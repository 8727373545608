import axios from "axios";
import React, { useEffect, useState } from "react";
import { useTranslation } from 'react-i18next';
import { VerticalTimeline, VerticalTimelineElement } from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';
import { ReactComponent as AcademicIcon } from '../../assets/academic.svg';
import { ReactComponent as Bookicon } from '../../assets/book.svg';
import { ReactComponent as Staricon } from '../../assets/star.svg';
import MorteIcon from '../../assets/tomb.png';
import baseURL from '../../config';
import './mobilita.css';

const Mobilita = React.forwardRef((props, ref ) => {
    const [mobilita, setMobilita] = useState([]);
    const schedaIntellettuale = props.schedaIntellettuale;
    const { t} = useTranslation();

    const compareEvents = (a,b) => {
        if (a.anno < b.anno) return -1;
        if (a.anno > b.anno) return 1;
        if (a.ordine < b.ordine) return -1;
        if (a.ordine > b.ordine) return 1;
        return 0;
    }

    useEffect(() => {
        const fetchData = async () =>{
            const mobilitaURL = baseURL+`/mobilita?filter={"where": {"idScheda": ${schedaIntellettuale.id}}}`
            await axios.get(mobilitaURL). then((response) => {
                const eventi = response.data;
                eventi.sort(compareEvents);
                setMobilita(eventi);
                if (eventi.length!==0) {
                    props.setTimelinePresent(true);
                }
            });
        }
        fetchData();
      },[]);

    const scrollToRef = (ref) => window.scrollTo(0, ref.current.offsetTop);

    const onEventClick = (evento) => {
        if (evento.latitude){
            props.setMapEvent([evento.latitude, evento.longitude]);
            scrollToRef(ref.current.mapRef);
        }
    }

    if(mobilita.length===0) return null;

    return(
        <div className="int__mobilita-main" ref={ref.current.mobilitaRef}>
            <div className="int__mobilita-title">
                {t("Timeline della mobilità")}
            </div>
            {mobilita ? <VerticalTimeline animate={false} layout="1-column-left" lineColor={ '#d3d3d360'} className={"int__mobilita-timeline" }> 
                {mobilita.map((evento) => {
                    let colore_icona='#9A1413';
                    let bordertop="3px solid #d3d3d320";
                    let actualIcon=<AcademicIcon/>;
                    if (evento.importanza===0) {
                        colore_icona='lightgray';
                        bordertop="3px solid #d3d3d320";
                        actualIcon=<Staricon/>;
                    } else if (evento.importanza===1) {
                        colore_icona='#e86e6e';
                        bordertop="3px solid #d3d3d320";
                        actualIcon=<Bookicon/>;
                    } else if (evento.importanza === 4) {
                        colore_icona='lightgray';
                        bordertop="3px solid #d3d3d320";
                    }
                    return (
                    <VerticalTimelineElement
                    key={evento.id}
                    className= {evento.latitude ? "int__mobilita-linebox-link" : "int__mobilita-linebox" }
                    contentStyle={{ background: '#d3d3d320', color: '#000', borderTop: bordertop }}
                    contentArrowStyle={{ borderRight: '7px solid  #d3d3d320'  }}
                    iconStyle={{ background: colore_icona, color: '#000' }}
                    /* icon={actualIcon} */
                    onTimelineElementClick={() => onEventClick(evento)}>
                        {evento.dataCerta === 1 || evento.dataCerta === null
                        ? <h1 className="int__mobilita-data"> {evento.anno}</h1>
                        : <h1 className="int__mobilita-data"> ? </h1>
                        }               
                        <h2 className="int__mobilita-boxsubtitle">{evento.luogoPrincipale}</h2>
                        {(evento.titoloBreve !== "Morte" && evento.titoloBreve !== "Death") 
                            ? <h3 className="int__mobilita-boxtitle">{evento.titoloBreve}</h3>
                            : <img src={MorteIcon}/>
                        }
                        
                        <h5 className="int__mobilita-boxsubsubtitle">{evento.luoghiEntiStudio}</h5>    
                        <p className="int__mobilita-posizioni">{evento.posizioni}</p>
                        <h5 className="int__mobilita-boxsubsubtitle">{evento.areaProfessionale}</h5>
                    </VerticalTimelineElement>
                    )
                })}
            </VerticalTimeline> : null}
        
        </div>
    )
})

export default Mobilita