import React, { useRef, useEffect, useState } from "react";
import './sidebar.css';
import { Gallery, Mobilita, Sidemenu, SearchPdf, Videos } from "../../components";
import { useNavigate } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import BackIcon from "../../assets/left1.png";
import baseURL from "../../config";
import axios from "axios";

const ArchiviAbbreviazioniButton = (props) => {
    const { t, i18n } = useTranslation();

    useEffect(() => {
        if (i18n.language.substring(0, 2) === "it") {
            axios.get(baseURL + "/contenuti/32").then((response) => {
                props.setModalContent(response.data);
            });
        } else {
            axios.get(baseURL + "/contenuti/9").then((response) => {
                props.setModalContent(response.data);
            });
        }
    }, [t]);

    const handleClick = () => props.setOpenModal(true);

    return (
        <div className="int__sidebar-abbreviazioni" onClick={handleClick}>
            <p>{t("Abbreviazioni")}</p>
        </div>
        /* <button className="int__sidebar-pdf-pages-button" onClick={handleClick}>
            {t("Abbreviazioni")}
        </button> */
    )
}

const Sidebar = React.forwardRef((props, ref) => {
    const { t, i18n } = useTranslation();
    const [fileScheda, setFileScheda] = useState(null);

    const schedaIntellettuale = props.schedaIntellettuale;
    const setMapEvent = props.setMapEvent;


    useEffect(() => {
        if (schedaIntellettuale.fileschedaattivo) {
            const fileSchedeURL = baseURL + `/fileSchede/${schedaIntellettuale.fileschedaattivo}`;
            axios.get(fileSchedeURL).then((response) => {
                setFileScheda(response.data);
            }).catch((error) => {
                console.error("error:", error);
                setFileScheda(null);
            });
        } else {
            setFileScheda(null);
        };
    }, [props]);

    const DownloadPdfButton = (props) => {
        const donwloadPdf = () => {
            const link = document.createElement("a");
            const filename = props.fileScheda.nomefile.substring(0, props.fileScheda.nomefile.lastIndexOf(".")) + ".pdf";
            link.download = props.fileScheda.id;
            link.href = baseURL + "/files/" + filename;
            link.click();
        }
        return (
            <button className="int__sidebar-pdf-pages-button" onClick={donwloadPdf}>
                {t("Scarica PDF")}
            </button>
        )
    }

    return (
        <div className="int__sidebar">
            {/* <div className="int__sidebar-backbutton" onClick={() => navigate("/elenco_intellettuali")}>
                <img src={BackIcon}/>
                <p>{t("Torna all'elenco")}</p>
            </div> */}
            {/* <div className="int__sidebar-hr"></div> */}
            {fileScheda &&
                <div className="int__sidebar-pdf-menu">
                    <SearchPdf {...{ searchString: props.searchString, setSearchString: props.setSearchString }} />
                    <ArchiviAbbreviazioniButton {...{ setOpenModal: props.setOpenModal, setModalContent: props.setModalContent }} />
                    <DownloadPdfButton {...{ fileScheda: fileScheda }} />

                </div>
            }
            <Sidemenu {...{
                timelinePresent: props.timelinePresent,
                mapPresent: props.mapPresent,
                viteConnessePresent: props.viteConnessePresent
            }}
                ref={ref} />
            <Gallery schedaIntellettuale={schedaIntellettuale} />
            <Videos {...{ schedaIntellettuale: schedaIntellettuale }} />
            <Mobilita {...{
                schedaIntellettuale: schedaIntellettuale,
                setMapEvent: setMapEvent,
                setTimelinePresent: props.setTimelinePresent
            }}
                ref={ref} />
        </div>
    )
});

export default Sidebar