import React, { useCallback, useEffect, useState } from 'react';
import axios from 'axios';
import baseURL from '../../config';
import { useTranslation } from "react-i18next";
import { useNavigate } from 'react-router-dom';



const ViteConnesse = React.forwardRef((props, ref) => {
  const [viteConnesseData, setViteConnesseData] = useState([]);
  const [viteConnesseConScheda, setViteConnesseConScheda] = useState([]);
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();

  const compareViteConnesse = (a, b) => {
    if (a.cognome < b.cognome) return -1;
    if (a.cognome > b.cognome) return 1;
    if (a.nome < b.nome) return -1;
    if (a.nome > b.nome) return 1;
    return 0;
  }

  useEffect(() => {
    const fetchData = async () => {
      const lingua = i18n.language.substring(0, 2) === "it" ? 1 : 2;
      let viteconnesse = [];
      let viteconnesseConSchede = [];
      //prendo tutte le relazioni in cui è coinvolto l'intellettuale
      const viteconnesseUrl = baseURL + `/viteconnesse?filter={"where": {"or" : [{"idintellettuale1" :${props.idIntellettuale}},
                                                                               {"idintellettuale2" :${props.idIntellettuale}}
                                                                              ]}}`;
      await axios.get(viteconnesseUrl).then(async (response) => {
        await Promise.all(response.data.map(async (relazione) => { //per ogni intellettuale in relazione
          if (relazione.idintellettuale1 === props.idIntellettuale) {
            await axios.get(baseURL + "/intellettuali/" + relazione.idintellettuale2) //recupero le informazioni sull'intellettuale connesso
              .then(async (response1) => {
                await axios.get(baseURL + `/schede?filter={"where": {"and" :[{"idIntellettuale": ${relazione.idintellettuale2}}, {"lingua" :${lingua}}, {"pubblica" : "true"}]}}`) //recupero le informazioni sull'intellettuale connesso
                  .then((response2) => {
                    if (response2.data.length !== 0) {
                      viteconnesseConSchede.push({//creo un nuovo nodo con tali informazioni
                        "id": response1.data.id,
                        "nome": response1.data.nome,
                        "cognome": response1.data.cognome
                      });
                    } else {
                      viteconnesse.push({//creo un nuovo nodo con tali informazioni
                        "id": response1.data.id,
                        "nome": response1.data.nome,
                        "cognome": response1.data.cognome
                      });
                    }
                  })
                  .catch(error => console.error("error:", error));
              })
              .catch(error => console.error("error:", error));
          } else {
            await axios.get(baseURL + "/intellettuali/" + relazione.idintellettuale1) //recupero le informazioni sull'intellettuale connesso
              .then(async (response1) => {
                await axios.get(baseURL + `/schede?filter={"where": {"and" :[{"idIntellettuale": ${relazione.idintellettuale1}}, {"lingua" :${lingua}}, {"pubblica" : "true"}]}}`) //recupero le informazioni sull'intellettuale connesso
                  .then((response2) => {
                    if (response2.data.length !== 0) {
                      viteconnesseConSchede.push({//creo un nuovo nodo con tali informazioni
                        "id": response1.data.id,
                        "nome": response1.data.nome,
                        "cognome": response1.data.cognome
                      });
                    } else {
                      viteconnesse.push({//creo un nuovo nodo con tali informazioni
                        "id": response1.data.id,
                        "nome": response1.data.nome,
                        "cognome": response1.data.cognome
                      });
                    }
                  })
                  .catch(error => console.error("error:", error));
              })
              .catch(error => console.error("error:", error));
          }
        }));
        viteconnesse.sort(compareViteConnesse);
        viteconnesseConSchede.sort(compareViteConnesse);
        setViteConnesseData(viteconnesse);
        setViteConnesseConScheda(viteconnesseConSchede);
        if (viteconnesse.length !== 0 || viteconnesseConSchede.length !== 0) {
          props.setViteConnessePresent(true)
        }
      })
        .catch(error => console.error("error:", error));;
    };
    fetchData();
  }, []);


  const clickHandler = (vita) => {
    navigate(`/${i18n.language.substring(0, 2)}/${vita.cognome}/${vita.nome}/${vita.id}`);
    window.location.reload();
  }

  if (viteConnesseData.length === 0 && viteConnesseConScheda.length === 0) return null;

  return (
    <div className='int__viteconnesse'>
      {(viteConnesseData.length !== 0 || viteConnesseConScheda.length !== 0) &&
        <>
          <div className="int__relazioni-title" ref={ref.current.viteconnesseRef}>
            {t("Vite connesse")}
          </div>
          {viteConnesseConScheda.map((vita) => (
            <div className='int__viteconnesse-link' key={vita.id} onClick={() => clickHandler(vita)}>
              &nbsp;{vita.cognome}, {vita.nome}
            </div>
          ))}
          {viteConnesseData.map((vita) => (
            <div className='int__viteconnesse-no-link' key={vita.id} onClick={() => clickHandler(vita)}>
              &nbsp;{vita.cognome}, {vita.nome}
            </div>
          ))}
        </>
      }

    </div>
  );
});

export default ViteConnesse;

