import React from "react";
import { useTranslation } from 'react-i18next';
import './sidemenu.css';

const scrollToRef = (ref) => window.scrollTo(0, ref.current.offsetTop);

const Sidemenu = React.forwardRef( (props, ref ) => {
    
    const { t } = useTranslation();
    const divRefs = ref.current;
        
    const scrollToFamiliari = () => scrollToRef(divRefs.familiariRef);
    const scrollToViteConnesse = () => scrollToRef(divRefs.viteconnesseRef);
    const scrollToEnti = () => scrollToRef(divRefs.entiRef);
    const scrollToReferenze = () => scrollToRef(divRefs.referenzeRef);
    const scrollToRelazioni = () => scrollToRef(divRefs.relazioniRef);
    const scrollToMap = () => scrollToRef(divRefs.mapRef);
    const scrollToMobilita = () => scrollToRef(divRefs.mobilitaRef);
    

    /* if (!familiariRef.current && !viteconnesseRef.current && !entiRef.current && !referenzeRef.current && !relazioniRef.current && !mapRef.current && !mobilitaRef.current) {
        return(
        <div className="int__sidemenu-main">
            <div className="int__sidemenu-title">
                <p>{t("Approfondimenti non presenti")}</p>
            </div>        
        </div>
        )
    } */


    return(
        <div className="int__sidemenu-main">
            <div className="int__sidemenu-menu">
                {props.viteConnessePresent && 
                <div className="int__sidemenu-menu-item" onClick={scrollToViteConnesse} key={"vite_connesse"}>
                    <p> &#62; {t("Vite connesse")}</p>
                </div>
                }
                {props.mapPresent && 
                <div className="int__sidemenu-menu-item" onClick={scrollToMap} key={"mappa_mob"}>
                    <p> &#62; {t("Mappa della mobilità")}</p>
                </div>
                }
                {props.timelinePresent && 
                <div className="int__sidemenu-menu-item" onClick={scrollToMobilita} key={"timeline"}>
                    <p> &#62; {t("Timeline della mobilità")}</p>
                </div>
                }
            </div>
            <div className="int__sidemenu-title" key={"approfondimenti_title"}>
                <p>{t("Approfondimenti")}</p>
            </div>
            <div className="int__sidemenu-menu">
                {divRefs.familiariRef.current && 
                <div className="int__sidemenu-menu-item" onClick={scrollToFamiliari} key={"familiari"}>
                    <p> &#62; {t("Familiari in mobilità")}</p>
                </div>
                }
                {divRefs.entiRef.current && 
                <div className="int__sidemenu-menu-item" onClick={scrollToEnti} key={"enti_soccorso"}> 
                    <p> &#62; {t("Enti di soccorso")}</p>
                </div>
                }
                {divRefs.referenzeRef.current && 
                <div className="int__sidemenu-menu-item" onClick={scrollToReferenze} key={"referenze"}>
                    <p> &#62; {t("Referenze")}</p>
                </div>
                }
                {divRefs.relazioniRef.current &&
                <div className="int__sidemenu-menu-item" onClick={scrollToRelazioni} key={"relazioni_aiuto"}>
                    <p> &#62; {t("Relazioni di aiuto")}</p>
                </div>
                }
            </div>
        </div>                
    )
});

export default Sidemenu