import axios from "axios";
import React, { useState, useEffect } from "react";
import './video.css';

import { Dialog, DialogTitle, DialogContent, DialogActions } from "@mui/material";
import ImageGallery from 'react-image-gallery';
import "react-image-gallery/styles/css/image-gallery.css";
import baseURL from '../../config';
import ReactPlayer from 'react-player'
import iconaVideo from '../../assets/iconavideo.svg';

const VideoPreview = (url) => {
    const [canvas, setCanvas] = useState(null);

    useEffect(() => {
        const video = document.createElement("video");
        video.src = url;
        const onLoad = () => {
            const canvas = document.createElement("canvas");
            canvas.width = video.videoWidth;
            canvas.height = video.videoHeight;
            video.currentTime = 1;
            const ctx = canvas.getContext("2d");
            ctx.drawImage(video, 0, 0);
            setCanvas(canvas);
        };
        video.addEventListener("canplay", onLoad);
        return () => video.removeEventListener("load", onLoad);
    }, [url]);

    if (!canvas) return null;
    return (
        <div>
            <canvas ></canvas>
        </div>
    )
};

const Videos = (props) => {
    const [showVideoGallery, setShowVideoGallery] = useState(false);
    const schedaIntellettuale = props.schedaIntellettuale;
    const [videoGallery, setVideoGallery] = useState([]);
    const [runningVideo, setRunningVideo] = useState(null);

    const compareVideos = (a, b) => {
        if (a.ordine < b.ordine) return -1;
        if (a.ordine > b.ordine) return 1;
        return 0;
    }

    useEffect(() => {
        if (schedaIntellettuale) {
            const videoURL = baseURL + `/video?filter={"where": {"and" : [{"idscheda": ${schedaIntellettuale.id}}, {"pubblico" : "true"}]}}`
            axios.get(videoURL).then((response) => {
                const videos = response.data.map((video) => (
                    video.estensione === "YouTube"
                        ? {
                            url: video.video,
                            descrizione: video.descrizione,
                            ordine: video.ordine,
                            titolo: video.titolo
                        }
                        : {
                            url: baseURL + "/videos/" + video.video,
                            descrizione: video.descrizione,
                            ordine: video.ordine,
                            titolo: video.titolo
                        }

                ));
                videos.sort(compareVideos);
                setVideoGallery(videos);
            });
        }
    }, [schedaIntellettuale])


    const handleClick = (video) => {
        setRunningVideo(video);
        setShowVideoGallery(true);
    };

    const handleCloseClick = () => {
        setShowVideoGallery(false);
    };

    if (videoGallery.length === 0) return null;

    return (
        <>
            <div className="int__video-main">
                <div className="int__video-title" >
                    Video
                </div>
                {(videoGallery.map((video) => (
                    <div className="int__video-title-box" onClick={() => handleClick(video)}>
                        <img src={iconaVideo} alt="Video" className="int__video-icon" />
                        <span>{video.titolo}</span>
                    </div>
                )))
                }
            </div>
            {runningVideo &&
                <Dialog
                    maxWidth="lg"
                    open={showVideoGallery}
                    onClose={handleCloseClick}
                >
                    <DialogTitle>{runningVideo.titolo}</DialogTitle>
                    <DialogContent>
                        <div className="int__video-modal">
                            <ReactPlayer url={runningVideo.url} controls />
                        </div>
                        <div className="int__video-modal-descr">
                            {runningVideo.descrizione}
                        </div>
                    </DialogContent>

                    <DialogActions>

                    </DialogActions>
                </Dialog>
            }
        </>

    )
}

export default Videos