import MenuIcon from '@mui/icons-material/Menu';
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { RiCloseLine, RiMenu2Line } from 'react-icons/ri';
import {
    Link,
    Outlet,
    useLocation
} from "react-router-dom";
import routes from "../..";
import Signora from "../../assets/signora.jpeg";
import './navbar.css';

const MainMenu = () => {
    const {t, i18n} = useTranslation();
    const lang = i18n.language.substring(0,2);
    if (lang === "it") {
        return (
            <div className="int__navbar-mainmenu-container">
                <p><Link to={`/`} key={"home"}> {t("Home")} </Link></p>
                <p><Link to={`/${lang}/presentazione`} key={"presentazione"}> {t("Presentazione")} </Link></p>
                <ul style={{ listStyleType: "none", paddingLeft: "0rem" }}>
                    <li><p><Link to={`/${lang}/presentazione_seconda_ed`} key={"presentazione2"}> {t("Presentazione della 2. edizione")} </Link></p></li>
                </ul>
                <p><Link to={`/${lang}/emigrazione_intellettuale`} key={"emigrazione"}>{t("L'emigrazione intellettuale")} </Link></p>
                <ul style={{ listStyleType: "none", paddingLeft: "2rem" }}>
                    <li><p><Link to={`/${lang}/intellettuali_in_fuga`} key={"intellettuali_in_fuga"}> {t("Gli intellettuali in fuga. Chi erano?")} </Link></p></li>
                    <li><p><Link to={`/${lang}/nomi_e_cognomi`} key={"nomi_e_cognomi"}> {t("Nomi e cognomi")} </Link></p></li>
                    <li><p><Link to={`/${lang}/volti_persone_storie`} key={"volti_persone"}> {t("Volti, persone e storie")} </Link></p></li>
                </ul>
                <p><Link to={`/${lang}/viteinmovimento`} key={"viteinmovimento"}>{t("Vite in movimento")} </Link></p>
                <ul style={{ listStyleType: "none", paddingLeft: "2rem" }}>
                    <li><p><Link to={`/${lang}/elenco_intellettuali`} key={"elencointellettuali"}>{t("Elenco alfabetico")} </Link></p></li>
                    <li><p><Link to={`/${lang}/reti_familiari`} key={"reti_familiari"}> {t("Reti familiari")} </Link></p></li>
                    <li><p><Link to={`/${lang}/work_in_progress`} key={"wip"}> {t("Work in progress")} </Link></p></li>
                </ul>
                <p><Link to={`/${lang}/ricerche_risorse_digitali`} key={"ricerche_risorse"}>{t("Ricerche e risorse digitali")} </Link></p>
                <ul style={{ listStyleType: "none", paddingLeft: "2rem" }}>
                    <li><p><Link to={`/${lang}/avvertenze_ricerca`} key={"avvertenze_ricerca"}>{t("Avvertenze per la ricerca")} </Link></p></li>
                    <li><p><Link to={`/${lang}/guida_ricerca`} key={"guida_ricerca"}> {t("Guida alle ricerche")} </Link></p></li>
                    <li><p><Link to={`/${lang}/fotografie`} key={"fotografie"}> {t("Fotografie: il nostro archivio")} </Link></p></li>
                </ul>
                <p><Link to={`/${lang}/normative`} key={"normative"}>{t("Normative fasciste e normative riparatorie (1925-2000)")} </Link></p>
                <ul style={{ listStyleType: "none", paddingLeft: "2rem" }}>
                    <li><p><Link to={`/${lang}/leggi_fascistissime`} key={"leggi_fascistissime"}> {t("Leggi fascistissime e leggi razziste")} </Link></p></li>
                    <li><p><Link to={`/${lang}/accanimento_e_riparazione`} key={"accanimento"}> {t("Tra accanimento e riparazione")} </Link></p></li>
                    <li><p><Link to={`/${lang}/anni_della_repubblica`} key={"anni_repubblica"}> {t("Gli anni della Repubblica")}</Link></p></li>
                </ul>
                <div className="int__navbar-mainmenu-final">
                    <p><Link to={`/${lang}/archivi_consultati`} key={"archiviconsultati"}> {t("Archivi consultati")} </Link></p>
                    <p><Link to={`/${lang}/bibliografia`} key={"bibliografia"}> {t("Bibliografia")} </Link></p>
                    <p><Link to={`/${lang}/credits`} key={"credits"}> {t("Credits")} </Link></p>
                    <p><Link to={`/${lang}/autrici_e_autori`} key={"autrici_autori"}> {t("Autrici e autori")} </Link></p>
                    <p><Link to={`/${lang}/traduttrici_e_traduttori`} key={"traduttrici_traduttori"}> {t("Traduttrici e traduttori")} </Link></p>
                    <p><Link to={`/${lang}/ringraziamenti`} key={"ringraziamenti"}> {t("Ringraziamenti")} </Link></p>
                    <p><Link to={`/${lang}/cosa_ce_di_nuovo`} key={"novita_aggiornamenti"}> {t("Cosa c'è di nuovo")} </Link></p>
                    <p><Link to={`/${lang}/dicono_di_noi`} key={"dicono_di_noi"}> {t("Dicono di noi")} </Link></p>
                    <p><Link to={`/${lang}/sostenitori`} key={"sostenitori"}> {t("Sostenitori e collaborazioni")} </Link></p>
                    <p><Link to={`/${lang}/licenze`} key={"licenze"}> {t("Licenze d'uso")} </Link></p>
                </div>
            </div>
            )
    } else {
        return(
            <div className="int__navbar-mainmenu-container">
                <p><Link to={`/`} key={"home"}> {t("Home")} </Link></p>
                <p><Link to={`/en/foreword`} key={"presentazione"}> {t("Presentazione")} </Link></p>
                <ul style={{ listStyleType: "none", paddingLeft: "0rem" }}>
                    <li><p><Link to={`/en/foreword_second_ed`} key={"presentazione2"}> {t("Presentazione della 2. edizione")} </Link></p></li>
                </ul>
                <p><Link to={`/en/intellectual_emigration`} key={"emigrazione"}>{t("L'emigrazione intellettuale")} </Link></p>
                <ul style={{ listStyleType: "none", paddingLeft: "2rem" }}>
                    <li><p><Link to={`/en/displaced_intellectuals`} key={"intellettuali_in_fuga"}> {t("Gli intellettuali in fuga. Chi erano?")} </Link></p></li>
                    <li><p><Link to={`/en/first_names_surnames`} key={"nomi_e_cognomi"}> {t("Nomi e cognomi")} </Link></p></li>
                    <li><p><Link to={`/en/faces_peoples_stories`} key={"volti_persone"}> {t("Volti, persone e storie")} </Link></p></li>
                </ul>
                <p><Link to={`/en/livesonthemove`} key={"viteinmovimento"}>{t("Vite in movimento")} </Link></p>
                <ul style={{ listStyleType: "none", paddingLeft: "2rem" }}>
                    <li><p><Link to={`/en/alphabetical_list`} key={"elencointellettuali"}>{t("Elenco alfabetico")} </Link></p></li>
                    <li><p><Link to={`/en/family_networks`} key={"reti_familiari"}> {t("Reti familiari")} </Link></p></li>
                    <li><p><Link to={`/en/work_in_progress`} key={"wip"}> {t("Work in progress")} </Link></p></li>
                </ul>
                <p><Link to={`/en/research_digital_resources`} key={"ricerche_risorse"}>{t("Ricerche e risorse digitali")} </Link></p>
                <ul style={{ listStyleType: "none", paddingLeft: "2rem" }}>
                    <li><p><Link to={`/en/notes_on_research`} key={"avvertenze_ricerca"}>{t("Avvertenze per la ricerca")} </Link></p></li>
                    <li><p><Link to={`/en/research_guide`} key={"guida_ricerca"}> {t("Guida alle ricerche")} </Link></p></li>
                    <li><p><Link to={`/en/photos`} key={"fotografie"}> {t("Fotografie: il nostro archivio")} </Link></p></li>
                </ul>
                <p><Link to={`/en/legislation`} key={"normative"}>{t("Normative fasciste e normative riparatorie (1925-2000)")} </Link></p>
                <ul style={{ listStyleType: "none", paddingLeft: "2rem" }}>
                    <li><p><Link to={`/en/leggi_fascistissime`} key={"leggi_fascistissime"}> {t("Leggi fascistissime e leggi razziste")} </Link></p></li>
                    <li><p><Link to={`/en/persecution_reparation`} key={"accanimento"}> {t("Tra accanimento e riparazione")} </Link></p></li>
                    <li><p><Link to={`/en/years_of_republic`} key={"anni_repubblica"}> {t("Gli anni della Repubblica")}</Link></p></li>
                </ul>
                <div className="int__navbar-mainmenu-final">
                    <p><Link to={`/en/consulted_archives`} key={"archiviconsultati"}> {t("Archivi consultati")} </Link></p>
                    <p><Link to={`/en/bibliography`} key={"bibliografia"}> {t("Bibliografia")} </Link></p>
                    <p><Link to={`/en/credits`} key={"credits"}> {t("Credits")} </Link></p>
                    <p><Link to={`/en/authors`} key={"autrici_autori"}> {t("Autrici e autori")} </Link></p>
                    <p><Link to={`/en/translators`} key={"traduttrici_traduttori"}> {t("Traduttrici e traduttori")} </Link></p>
                    <p><Link to={`/en/acknowledgements`} key={"ringraziamenti"}> {t("Ringraziamenti")} </Link></p>
                    <p><Link to={`/en/whats_new`} key={"novita_aggiornamenti"}> {t("Cosa c'è di nuovo")} </Link></p>
                    <p><Link to={`/en/about_us`} key={"dicono_di_noi"}> {t("Dicono di noi")} </Link></p>
                    <p><Link to={`/en/partnerships`} key={"sostenitori"}> {t("Sostenitori e collaborazioni")} </Link></p>
                    <p><Link to={`/en/licenses`} key={"licenze"}> {t("Licenze d'uso")} </Link></p>
                </div>
            </div>
        )
    }    
}

const Navbar = (props) => {
    const [toggleMenu, setToggleMenu] = useState(false);
    const [mobileMenu, setMobileMenu] = useState(false);
    const [isTitleVisible, setTitleVisible] = useState(true);
    const location = useLocation();
    const {t, i18n} = useTranslation();

    const listenToScroll = () => {
        let heightToHideFrom = 30;
        const winScroll = document.body.scrollTop || document.documentElement.scrollTop;
        if (winScroll > heightToHideFrom) {  
             isTitleVisible && setTitleVisible(false);
        } else {
             setTitleVisible(true);
        }  
    };

    useEffect(() => {
        setToggleMenu(props.toggleMenu);
    },[props.toggleMenu]);

    useEffect(() => {
        const closeToggle = e => {
            if (e.path) {
                if (e.path[1].tagName !== "BUTTON" && e.path[0].tagName !== "BUTTON" && e.path[1].tagName !== "svg" && !e.path.some(p => p.className==="int__header-title-container")) {
                    setToggleMenu(false);
                    if (props.setToggleMenu){
                        props.setToggleMenu(false);
                    }
                }
            } else {//per firefox e gli altri
                if (e.composedPath()[1].tagName !== "BUTTON" && e.composedPath()[0].tagName !== "BUTTON" && e.composedPath()[1].tagName !== "svg" && !e.composedPath().some(p => p.className==="int__header-title-container")) {
                    setToggleMenu(false);
                    if (props.setToggleMenu){
                        props.setToggleMenu(false);
                    }
                }
            }
            
        };
        document.body.addEventListener('click', closeToggle);
        return () => document.body.removeEventListener('click', closeToggle);
    },[]);

    useEffect(() => {   
        window.addEventListener("scroll", listenToScroll);
        return () => 
           window.removeEventListener("scroll", listenToScroll); 
    });
    
    const changeLanguage = (nextLanguage) => {
        const currentPathname = window.location.pathname;
        if (currentPathname==="/" || 
            currentPathname==="/it" || 
            currentPathname==="/it/" || 
            currentPathname==="/en" || 
            currentPathname==="/en/") {
                window.location.replace(`${nextLanguage}/`);
        } else {
            const currentPathSplitted = currentPathname.split("/",3);
            if (currentPathSplitted[1] === "it") {
                if (currentPathSplitted[2] == "elenco_intellettuali") {
                    const replacedPath = `/en`.concat(`/alphabetical_list`);
                    window.location.replace(replacedPath);
                    return;
                }
                if (currentPathSplitted[2] === "guida_ricerca") {
                    const replacedPath = `/en`.concat(`/research_guide`);
                    window.location.replace(replacedPath);
                    return;
                }
                //SI CERCA NELLE ROUTES ITALIANE
                const route = routes.find((route) => route.path_it===currentPathSplitted[2]);
                if (route) {
                    const replacedPath = nextLanguage.concat(`/${route.path_en}`);
                    window.location.replace(replacedPath);
                    return;
                }
            } else {
                if (currentPathSplitted[2] === "alphabetical_list") {
                    const replacedPath = `/it`.concat(`/elenco_intellettuali`);
                    window.location.replace(replacedPath);
                    return;
                }
                if (currentPathSplitted[2] === "research_guide") {
                    const replacedPath = `/it`.concat(`/guida_ricerca`);
                    window.location.replace(replacedPath);
                    return;
                }
                //SI CERCA NELLE ROUTES INGLESI
                const route = routes.find((route) => route.path_en===currentPathSplitted[2]);
                if (route) {
                    const replacedPath = nextLanguage.concat(`/${route.path_it}`);
                    window.location.replace(replacedPath);
                    return;
                }
            }
            
            //SE NESSUNO DEI CASI SOPRA ALLORA SIAMO IN UNA BIOGRAFIA
            const currentLang = currentPathname.substring(0,4)
            const replacedPath = currentPathname.replace(currentLang, `${nextLanguage}/`);
            window.location.replace(replacedPath);
            return;
        }
    };

    return(<>
        <div className="int__navbar">
            <div className='int__navbar-menu'>
            {toggleMenu 
                ?   <button type='button' onClick={()=> setToggleMenu(false)}>
                        <MenuIcon color='#fff' sx={{ fontSize: 45 }}/>
                        <div className="int__navbar-menu-indice">{t("Indice")}</div>
                    </button>
                :   <button type='button' onClick={()=> setToggleMenu(true)}>
                        <MenuIcon color='#fff' sx={{ fontSize: 45 }}/>
                        <div className="int__navbar-menu-indice">{t("Indice")}</div>
                    </button>
            }
            {toggleMenu && (
                <div className="int__navbar_home-background">
                    <div className='int__navbar-menu-container slide-in-left'>
                        <div className="int__navbar-menu-frontespizio">
                            <div className="int__navbar-menu-frontespizio-logo"><img src={Signora}/></div>
                            <div className="int__navbar-menu-frontespizio-contenuti">
                                <div className="int__navbar-menu-frontespizio-autore">Patrizia Guarnieri</div>
                                <div className="int__navbar-menu-frontespizio-titolo">{t("Intellettuali in fuga dall'Italia fascista")}</div>
                                <div className="int__navbar-menu-frontespizio-sottotitolo">{t("Migranti, esuli e rifugiati per motivi politici e razziali")}</div>
                                <div className="int__navbar-menu-frontespizio-copyright">{t("Firenze, Firenze University Press, 2023- 2. edizione riveduta e ampliata (1.ed. 2019-22)")}</div>
                                <div className="int__navbar-menu-frontespizio-isbn">IT: <span style={{ fontWeight: 600 }}>e-ISBN:</span> 978-88-5518-648-3 &nbsp; | &nbsp; <span style={{ fontWeight: 600 }}>DOI: </span> 10.36253/978-88-5518-648-3</div>
                                <div className="int__navbar-menu-frontespizio-isbn">EN: <span style={{ fontWeight: 600 }}>e-ISBN:</span> 979-12-215-0032-5 &nbsp; | &nbsp; <span style={{ fontWeight: 600 }}>DOI: </span> 10.36253/979-12-215-0032-5</div>
                                <div className="int__navbar-menu-frontespizio-copyright">{t("Biblioteca di storia - ISSN online: 2704-5986")}</div>
                            </div>
                        </div>
                        <div className="int__navbar-menu-frontespizio-resp-isbn">IT: <span style={{ fontWeight: 600 }}>e-ISBN:</span> 978-88-5518-648-3 &nbsp; | &nbsp; <span style={{ fontWeight: 600 }}>DOI: </span> 10.36253/978-88-5518-648-3</div>
                        <div className="int__navbar-menu-frontespizio-resp-isbn">EN: <span style={{ fontWeight: 600 }}>e-ISBN:</span> 979-12-215-0032-5 &nbsp; | &nbsp; <span style={{ fontWeight: 600 }}>DOI: </span> 10.36253/979-12-215-0032-5</div>
                        <div className="int__navbar-menu-frontespizio-resp-copyright">{t("Biblioteca di storia - ISSN online: 2704-5986")}</div>
                        <div className="int__navbar-menu-frontespizio-hr"></div>
                        <MainMenu/>
                    </div>
                </div>
                )}
            </div>
            <div className='int__navbar-mobile-menu'>
                {mobileMenu 
                ? <button type='button'><RiCloseLine color='#fff' size={27} onClick={()=> setMobileMenu(false)}/></button>
                : <button type='button'><RiMenu2Line color='#fff' size={27} onClick={()=> setMobileMenu(true)}/></button>
                }
                {mobileMenu && (
                    <div className='int__navbar-mobile-menu-container scale-up-top'>
                        <div className='int__navbar-mobile-menu-container-menu'>
                            <MainMenu/>
                        </div>
                    </div>
                )}
            </div>
            {(isTitleVisible &&  !location.pathname.endsWith("/") && 
                                 !location.pathname.endsWith("/it/") && 
                                 !location.pathname.endsWith("/en/") && 
                                 !location.pathname.endsWith("/it") && 
                                 !location.pathname.endsWith("/en" ))
                ?   <div className='int__navbar-site-title-bio'>
                        <p>{t("Intellettuali in fuga dall'Italia fascista")}</p>
                    </div>    
                :   null
            }
            <div className='int__navbar-lang-button'>
                {i18n.language.substring(0,2) === "it"
                ? <button type='button' onClick={() => changeLanguage("/en")}>En</button>
                : <button type='button' onClick={() => changeLanguage("/it")}>It</button>
                }
                
            </div>
        </div>
        <Outlet/>
        </>
    )
}

export default Navbar