import React, {useEffect, useState } from 'react'
import axios from "axios"
import './App.css';
import { useParams, Outlet, Link, useLocation } from 'react-router-dom';
import {Title} from './components';
import Maincontainer from './containers/main/MainContainer';
import baseURL from './config';
import { useTranslation } from 'react-i18next';
import ReactGA from 'react-ga4';
    
function Scheda() {
    let params = useParams();
    const { state } = useLocation();
    const [intellettuale, setIntellettuale] = useState();
    const [lang, setLang] = useState(null);
    const [schedaIntellettuale, setSchedaIntellettuale] = useState(null); 
    const [searchString, setSearchString] = useState(state ? state.searchString : "");
    const { t, i18n } = useTranslation(); 
    
    window.addEventListener("popstate", () => {
        window.location.reload();
    });

    useEffect(() => {
      /* const lingua = i18n.language.substring(0,2) === "it" ? 1 : 2; */
      const lingua = params.lang === "it" ? 1 : 2;
      i18n.changeLanguage(params.lang)
      setLang(i18n.language.substring(0,2));  
      axios.get(baseURL+"/intellettuali/"+params.id).then((response) => {
        setIntellettuale(response.data);
        });
      const schedeURL = baseURL+`/schede?filter={"where": {"and" :[{"idIntellettuale": ${params.id}}, {"lingua" :${lingua}}, {"pubblica" : "true"}]}}`
      axios.get(schedeURL).then((response) => {
        setSchedaIntellettuale(response.data[0]);
      });
    },[]);

    useEffect(() => {
        ReactGA.send({ hitType: "pageview", page_title: window.location.pathname});
      },[window.location.pathname]);
  
    return (
      <div>
          <div className="int__contenuti-navbar">
              <div className="int__contenuti-navbar-horiz">
                    <div className="int__contenuti-navbar-left-container">
                        <div className="int__contenuti-navbar-left-container-box">
                            {lang==="it" ? 
                                <Link to={`/${lang}/viteinmovimento`} key={"viteinmovimento"}>
                                    <button className="int__contenuti-pages-button">
                                        <img src={require("./assets/left2.png")} alt="Left" className="int__contenuti-icons"></img>
                                    </button>
                                    <div className='int__contenuti-navbar-title'>{t("Vite in movimento")} </div>
                                </Link>
                                :
                                <Link to={`/${lang}/livesonthemove`} key={"viteinmovimento"}>
                                    <button className="int__contenuti-pages-button">
                                        <img src={require("./assets/left2.png")} alt="Left" className="int__contenuti-icons"></img>
                                    </button>
                                    <div className='int__contenuti-navbar-title'>{t("Vite in movimento")} </div>
                                </Link>
                            }
                        </div>
                    </div>                        
                    <div className="int__contenuti-navbar-right-container">
                        <div className="int__contenuti-navbar-right-container-box">
                            {lang==="it" ? 
                                <Link to={`/${lang}/elenco_intellettuali`} key={"elenco_link"}>
                                    <button className="int__contenuti-pages-button">
                                        <img src={require("./assets/right2.png")} alt="Right" className="int__contenuti-icons"></img>
                                    </button>
                                    <div className="int__contenuti-navbar-title">{t("Elenco alfabetico")}</div>
                                </Link>
                                :
                                <Link to={`/${lang}/alphabetical_list`} key={"elenco_link"}>
                                    <button className="int__contenuti-pages-button">
                                        <img src={require("./assets/right2.png")} alt="Right" className="int__contenuti-icons"></img>
                                    </button>
                                    <div className="int__contenuti-navbar-title">{t("Elenco alfabetico")}</div>
                                </Link>
                            }   
                        </div>
                    </div>
              </div>
          </div>
      {(intellettuale && schedaIntellettuale) ? <Title {...{intellettuale:{intellettuale}, scheda:{schedaIntellettuale}}}/> : null}
      {(schedaIntellettuale && intellettuale) ? <Maincontainer {...{schedaIntellettuale:{schedaIntellettuale}, intellettuale:intellettuale, searchString:searchString, setSearchString:setSearchString}}/> : <h1> Loading </h1>}
      </div>
    );
  }
  
  export default Scheda;